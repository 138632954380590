
<template>
    <div id="app" v-on:click="playClick" :class="{mobile: isMobile}" :style="lightTheme ? cssVarsLight : cssVars" >
        <div class="blur-container" :class="{blur: blur}">
            <marquee class="alert-message-marquee" v-if="!modals.fullscreen && alertMessage">
                {{ alertMessage }}
            </marquee>
            <Header/>
            <div class="main">
                <SideMenu/>
                <Menu :class="{hide: !isMobile}"/>
                <MenuModal/>
                <div class="bigPane">
                    <Grid/>
                    <OrdersPane/>
                    <StatusBar/>
                </div>
            </div>
            <Footer/>
        </div>
        <LoginModal v-if="!['tradingviewonline'].includes(brand)"/>
        <CustomLoginModals v-else/>
        <NoLandscape/>
        <ProfitCalculatorModal/>
        <AssetsInfoModal/>
        <RegisterModal/>
        <AccountModal/>
        <SymbolsModal/>
        <BalanceModal/>
        <AccountModal/>
        <GridsModal/>
        <NoConnectionModal/>
        <AutoCloseModal/>
        <PendingModal/>
        <Notifications/>
        <ForgotPasswordModal/>
        <NewPasswordModal/>
        <ClosePositionModal/>
        <DepositSuccessModal/>
        <DepositFailModal/>
        <WithdrawalCancelModal/>
        <Fullscreen/>
        <AutoTraderModal/>
        <PciDssModal />
        <QrCodeModal />
        <TimePickerModal/>
        <AlertMessageModal v-if="alertMessage" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL} from "@/store/mutations.type"
import {APP_INIT, PLAY_CLICK} from '@/store/actions.type'
import {isMobile} from '@/common/helpers'

import Header from "@/components/Header"
import Footer from "@/components/Footer"
import SideMenu from "@/components/SideMenu"
import NoLandscape from "@/components/Modals/NoLandscape"
import SymbolsModal from "@/components/Modals/SymbolsModal"
import ProfitCalculatorModal from "@/components/Modals/ProfitCalculatorModal"
import AssetsInfoModal from "@/components/Modals/AssetsInfoModal"
import BalanceModal from "@/components/Modals/BalanceModal"
import LoginModal from "@/components/Modals/LoginModal"
import CustomLoginModals from "@/components/CustomLoginModals"
import GridsModal from "@/components/Modals/GridsModal"
import AccountModal from "@/components/Modals/AccountModal"
import Notifications from "@/components/Notifications"
import RegisterModal from "@/components/Modals/RegisterModal"
import NoConnectionModal from '@/components/Modals/NoConnectionModal'
import MenuModal from '@/components/Modals/MenuModal'
import Menu from '@/components/Modals/HamburgerMenu'
import AutoCloseModal from '@/components/Modals/AutoCloseModal'
import PendingModal from '@/components/Modals/PendingModal'
import ForgotPasswordModal from '@/components/Modals/ForgotPasswordModal'
import NewPasswordModal from '@/components/Modals/NewPasswordModal'
import ClosePositionModal from '@/components/Modals/ClosePositionModal'
import DepositSuccessModal from '@/components/Modals/DepositSuccessModal'
import DepositFailModal from '@/components/Modals/DepositFailModal'
import WithdrawalCancelModal from '@/components/Modals/WithdrawalCancelModal'
import PciDssModal from "@/components/Modals/PciDssModal"
import QrCodeModal from "@/components/Modals/QrCodeModal"
import AlertMessageModal from "@/components/Modals/AlertMessageModal"
import Grid from '@/components/Grid'
import OrdersPane from "@/components/OrdersPane"
import StatusBar from "@/components/StatusBar"
import Fullscreen from "@/components/Modals/Fullscreen"

import {
    BRAND,
    COLOR_FONT,
    COLOR_BG_FOOTER,
    COLOR_BG_DARK,
    COLOR_BG_DARK_MID,
    COLOR_BG,
    COLOR_BG_LIGHT,
    COLOR_WHITE,
    COLOR_BRAND,
    COLOR_GREEN,
    COLOR_GREEN_BUTTON,
    COLOR_RED,
    COLOR_PRIMARY_BUTTON,
    COLOR_SECONDARY_BUTTON,
    COLOR_BORDER,
    COLOR_MARKET_WATCH_TITLE, COLOR_BG_ASSETS_INFO, COLOR_BG_LOGIN, COLOR_BG_LOGIN_BTN, CUSTOM_LOGIN_MODAL
} from "@/common/config"
import AutoTraderModal from "@/components/Modals/AutoTraderModal"
import TimePickerModal from "@/components/Modals/TimePickerModal";
// import TimePickerModal from "@/components/Modals/TimePickerModal";

export default {
    name: 'App',
    components: {
        TimePickerModal,
        AutoTraderModal,
        StatusBar,
        OrdersPane,
        Grid,
        PendingModal,
        AutoCloseModal,
        MenuModal,
        Menu,
        AssetsInfoModal,
        NoConnectionModal,
        NoLandscape,
        ProfitCalculatorModal,
        RegisterModal,
        Notifications,
        AccountModal,
        GridsModal,
        LoginModal,
        CustomLoginModals,
        BalanceModal,
        ForgotPasswordModal,
        NewPasswordModal,
        ClosePositionModal,
        DepositSuccessModal,
        DepositFailModal,
        WithdrawalCancelModal,
        SymbolsModal, SideMenu, Footer, Header,
        Fullscreen,
        PciDssModal,
        QrCodeModal,
        AlertMessageModal
    },
    async mounted() {
        this.$store.dispatch(APP_INIT)
        if (isMobile()) {
            this.$store.commit(MODAL, {
                menu: false,
                sideMenu: false
            })
        }
        // try {
        //     await axios.get(STATIC_BASE)
        // } catch (error) {
        //     this.$store.commit(SET_STATIC_BASE, 'eu')
        // }
        window.onresize = () => {
            window.scrollTo(0, document.body.scrollHeight)

            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        // screen.orientation.lock('landscape')

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    data() {
        return {
            brand: BRAND,
            customLoginModal: CUSTOM_LOGIN_MODAL,
            sessionStateInit: false,
            isMobile: isMobile(),
            cssVars: {
                '--font': COLOR_FONT,
                '--background-footer': COLOR_BG_FOOTER,
                '--background-dark': COLOR_BG_DARK,
                '--background-dark-mid': COLOR_BG_DARK_MID,
                '--background': COLOR_BG,
                '--background-light': COLOR_BG_LIGHT,
                '--white': COLOR_WHITE,
                '--white-to-black': COLOR_WHITE,
                '--brand': COLOR_BRAND,
                '--green': COLOR_GREEN,
                '--green-button': COLOR_GREEN_BUTTON,
                '--red': COLOR_RED,
                '--primary-button': COLOR_PRIMARY_BUTTON,
                '--secondary-button': COLOR_SECONDARY_BUTTON,
                '--border': COLOR_BORDER,
                '--market-watch-title': COLOR_MARKET_WATCH_TITLE,
                '--assets-info-gradient': COLOR_BG_ASSETS_INFO,
                '--color-bg-login': COLOR_BG_LOGIN,
                '--color-bg-login-btn': COLOR_BG_LOGIN_BTN,

                '--aspin-background-light': COLOR_BG_LIGHT,
            },
            cssVarsLight: {
                '--font': '#313131',
                '--background-footer': '#fff',
                '--background-dark': '#fff',
                '--background-dark-mid': '#fbfbfb',
                '--background': '#fbfbfb',
                '--brand': COLOR_BRAND,
                '--border': '#E8F2F9',
                '--white-to-black': '#000',
                '--assets-info-gradient': 'linear-gradient(0deg, rgba(245, 255, 245, 1) 0%, rgba(245, 255, 245, .7) 45%, rgba(24, 31, 45, 0.45) 100%)',

                '--aspin-background-light': '#fbfbfb'

            },

        }
    },
    methods: {
        playClick() {
            this.$store.dispatch(PLAY_CLICK)
        },

    },
    computed: {
        ...mapGetters(['blur', 'symbols', 'sessionsState','staticBase','lightTheme','variables','modals']),
        alertMessage(){
            return this.variables.ALERT_MESSAGE ? this.variables.ALERT_MESSAGE.trim() : false
        }
    },
    // watch: {
    //     symbols() {
    //         if (!this.sessionStateInit) {
    //             this.$store.commit(SET_SESSION_TIME, this.symbols)
    //             this.sessionStateInit = true
    //         }
    //     }
    // }
}
</script>

<style>
@font-face {
    font-family: "Graphik-regular";
    src: url("~@/assets/fonts/Graphik-Regular-Trial.otf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

:root {
    --background-footer: #18202d;
    --background-dark: #181f2d;
    --background-dark-mid: #252c3b;
    --background: #374054;
    --background-light: #363f52;
    --white: #ffffff;
    --white-to-black: #ffffff;
    --whitesmoke-to-lightgrey:#E5E6ED;
    --font: #8c8f96;
    --brand: #ff7600;
    --green: #31cf5a;
    --green-button: #2bab40;
    --red: #dd4931;
    --primary-button: #ff7600;
    --secondary-button: #424a5e;
    --border: #353c4e;

    --fs: #0F111D;
    --fs-border: #FFFFFF4C;
    --fs-th: #1E213A7F;
    --fs-tr: #202542;

    --aspin-background: #1b1e29;
    --aspin-background-second: #262933;
    --aspin-background-third: #1E1F25;
    --aspin-background-active: #171721;
    --aspin-background-input: #34384C;
    --aspin-background-table: #2c2f40;
    --aspin-background-input-light: #5d6588;
    --aspin-background-light: #363f52;
    --aspin-color-label: #D9D9D9;
    --aspin-color-hover: #e3e3e3;


    --new-chat-color: #252c3b;
    --new-chat-bg:#181F2D ;
    --border-blue: #0D99FF;

}

body.light-theme{
    --background-footer: #fff;
    --background-dark:#fff;
    --background-dark-mid: var(--aspin-background-active);
    --background: #fbfbfb;
    --white-to-black: #000;
    --whitesmoke-to-lightgrey:#26272b;
    --font: #313131;
    --brand: #ff7600;
    --border: #E8F2F9;

    --aspin-background: #fff;
    --aspin-background-second:#fff;
    --aspin-background-third:#f8fbfd;
    --aspin-background-active: #fbfbfb;
    --aspin-background-input: #f8fbfd;
    --aspin-background-table: #fafbfd;
    --aspin-background-light: var(--aspin-background-active);
    --aspin-background-input-light: var(--aspin-background-active);
    --aspin-color-label: #818181;
    --aspin-color-hover: #fafbfd;
    --new-chat-color: #f8fbfd;
    --new-chat-bg:var(--aspin-background-active);
}

html, body, #app {
    position: fixed;
    height: 100%;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    font-weight: 100;
    background: var(--background-dark);
    color: var(--font);
}

[hidden] {
    display: none !important;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: text;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* scrollbar-color: rgba(255, 255, 255, 0.05) rgba(255, 255, 255, 0.05); */
    /* scrollbar-width: thin; */
}

*:focus {
    outline: none !important;
    caret-color: var(--white-to-black);
}

::placeholder {
    color: var(--secondary-button)
}

::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}

::-webkit-scrollbar-track {
    background: var(--background-dark);
}

::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
    background: var(--brand);
}

::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
}

.noscroll::-webkit-scrollbar {
    width: 0;
    height: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#app {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: scroll;
}

.blur-container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-size: cover;
    flex: 1 1 auto;
    overflow: hidden;
}

.hide {
    display: none !important;
}

.green {
    color: var(--green) !important;
}

.red {
    color: var(--red) !important;
}

.brand {
    color: var(--brand) !important;
}

.white {
    color: var(--white) !important;
}

.green-button {
    color: var(--white);
    background-color: var(--green-button)
}

.brand-button {
    color: var(--white);
    background-color: var(--brand)
}

.row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.show-hide-password {
    position: relative;
    width: 100%;
}

.show-hide-password i {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    cursor: pointer;
    font-size: 18px;
}

.show-hide-password input {
    max-width: 100%;
}

.newDesign .show-hide-password {
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
}

.newDesign .show-hide-password input {
    background: transparent;
}

.show-hide-password {
    background: var(--background);
    border-radius: 5px;
}

.show-hide-password input {
    padding: 8px 35px 8px 12px;
}

.stretch {
    align-items: stretch;
}

.flex-start {
    align-items: flex-start;
}

.col {
    display: flex;
    flex-direction: column;
}

.grid {
    display: flex;
    flex: 1 1 auto
}

.justify-end {
    justify-content: flex-end;
}

.align-end {
    align-items: flex-end;
}

.modal-wrapper {
    position: absolute;
    z-index: 1080 !important;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.modal {
    width: 300px;
    display: flex;
    background: var(--background-dark);
    border-radius: 3px;
}

.scroll {
    overflow-y: scroll;
    position: relative;
}

.shadow:after {
    content: '';
    position: absolute;
    z-index: 2;
    height: 30px;
    width: 100%;
    -webkit-box-shadow: inset 0 -20px 20px 0 var(--background-dark);
    -moz-box-shadow: inset 0 -20px 20px 0 var(--background-dark);
    box-shadow: inset 0 -20px 20px 0 var(--background-dark);
    bottom: 0;
    left: 0;
}

.overflow-hidden {
    overflow: hidden;
}

input {
    color: var(--white-to-black);
    -webkit-touch-callout: initial !important;
    -webkit-user-select: initial !important;
    -moz-user-select: initial !important;
    -ms-user-select: initial !important;
    user-select: initial !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, var(--white) 50%),
    linear-gradient(135deg, var(--white) 50%, transparent 50%);
    background-position: calc(100% - 0.75rem - 5px) 1em,
    calc(100% - 0.75rem) 1em;
    background-size: 5px 5px,
    5px 5px,
    1px 1.5em;
    background-repeat: no-repeat;
}

button {
    border: 1px solid var(--background);
    background: var(--background-light);
    border-radius: 3px;
    color: var(--white);
    cursor: pointer;
    padding: 8px 12px;
}

.form-icon {
    position: relative;
}

.form-control.form-image {
    padding: 8px 12px 8px 30px;
}

.form-icon img {
    width: 16px;
    height: 16px;
    object-fit: cover;
    position: absolute;
    top: 28px;
    left: 10px;
}

.form-icon .country {
    position: absolute;
    top: 31px;
    left: 10px;
}

.form-icon i {
    position: absolute;
    top: 28px;
    right: 10px;
    color: var(--white);
    cursor: pointer;
    font-size: 16px;
}

.form-control.phone {
    padding: 8px 12px 8px 30px;
}

.form-icon .select {
    font-size: 30px;
    top: 20px;
    right: 5px;
}

.phone-flag {
    position: absolute;
    left: 10px;
}

.form-icon label {
    margin-bottom: 5px;
    color: var(--white);
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
}

.form-icon input {
    width: 100%;
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
    color: var(--white);
}

label {
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 0.5em;
}

.form-control {
    color: var(--white-to-black);
    border: 0;
    padding: 8px 12px;
    cursor: pointer;
    background: var(--background);
}

.form-control::placeholder {
    color: var(--font)
}

.form-title {
    color: var(--white);
    font-weight: 600;
    font-size: 12px;
}

.aspin-design .form-control {
    color: var(--white-to-black);
}
.aspin-design .card-input.form-control {
    color: var(--white);
}

.aspin-design .form-title {
    color: var(--white-to-black);
    font-size: 32px;
}

.fullscreen-page-title {
    font-weight: 700;
    font-size: 24px;
    color: var(--white-to-black);
    text-align: center;
}

.form-row {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blur {
    -webkit-filter: blur(8px);
    -moz-filter: blur(8px);
    -o-filter: blur(8px);
    -ms-filter: blur(8px);
    filter: blur(8px);
}

.pointer {
    cursor: pointer;
}

.quote-block {
    background: var(--background-dark-mid);
    padding: 20px;
    width: 100%;
    line-height: 1.8;
    color: var(--white);
}

.table-cont {
    width: 100%;
    overflow-x: scroll;
}

table.cabinet {
    width: 100%;
}

.cabinet th {
    background: var(--fs-th);
    color: var(--white);
    padding: 8px;
    text-align: center;
}

.cabinet td {
    color: var(--white);
    font-size: 1.1em;
    font-weight: 400;
    border-bottom: 1px solid var(--border);
}

.cabinet td, th {
    text-align: center;
    border-right: 1px solid transparent;
    padding: 8px 20px;
}

.cabinet.fs td {
    font-weight: 300;
    border: none;
}

.cabinet.fs .th {
    border: none;
}

.widget .title {
    padding: 20px 10px 10px 20px;
    color: var(--white-to-black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3em;
}

.widget .title i {
    font-size: 20px;
    cursor: pointer;
}

.widget.item {
    display: flex;
    flex: 1 1 1px;
    margin-bottom: 5px;
    background: var(--background-dark);
    flex-direction: column;
    overflow: hidden;
    border-bottom: 1px solid var(--border);
}

.widget .content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0 10px;
}

.card-bg {
    border-radius: 10px;
    padding: 20px;
    background: var(--background-dark-mid);
}

.card-bg.activeLog {
    min-height: 200px;
}

.aspin-icon {
    position: absolute;
    top: calc(50% + 15px);
    transform: translateY(-50%);
    right: 20px;
    object-fit: contain;
}

/*--------------*/

.row.fs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.col.fs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.3vw;
}

input.fs, select.fs {
    border-radius: .4vw;
    background: var(--background-dark-mid);
    border: 1px solid var(--fs-border);
    position: relative;
}

select.fs {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px,
    5px 5px,
    1px 1.5em;
    background-repeat: no-repeat;
}

select.fs.activeSelect {
    background-position: calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px,
    5px 5px,
    1px 1.5em;
    background-repeat: no-repeat;
    outline: 0;
}

button.fs {
    width: 100%;
}

table.fs {
    border-collapse: collapse;
}

button.fs,
button.fs-small,
button.fs-normal {
    background: var(--green);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aspin-design button.fs,
.aspin-design button.fs-small,
.aspin-design button.fs-normal {
    border-radius: 100px;
    border: none;
}

tr.fs {
    /*background-color: var(--background);*/
}

tr.fs:nth-child(even) {
    background-color: var(--background-dark-mid);
}

thead .fs {
    /*background-color: var(--background);*/
}

tr.fs {
    /*border-top: 5px solid black;*/
}

th.fs {
    /*border-right: 10px solid black;*/
}

.fullscreen-page-sub-title {
    color: var(--white-to-black);
    font-size: 18px;
    font-weight: 400;
    padding: 0;
}

.form-control.fs {
    padding-right: 20px;
}

.aspin-design input.fs,
.aspin-design .form-control.fs {
    background: var(--aspin-background-input);
    border: 1px solid var(--aspin-background-input);
    border-radius: 100px;
    height: 75px;
    padding: 1.1em 24px;
}
.light-theme .aspin-design input.fs,
.light-theme .aspin-design .form-control.fs {
    background: var(--aspin-color-hover) !important;
}

.aspin-design .col.avatar {
    border: 2px dashed #F3F3F3;
    border-radius: 12px;
    background-color: transparent;
}

.aspin-design .quote-block {
    background: var(--aspin-background-table);
    border: 1px solid #CDCDCD;
    border-radius: 12px;
    padding: 35px;
    color: var(--white-to-black);
}

.aspin-design .show-hide-password {
    border: none;
}

.aspin-design .languages.fs .item,
.aspin-design .currency.fs .item {
    background: var(--aspin-background-input-light);
    /* Base/Grey 80 */
    border: 1px solid var(--aspin-background-input);
    border-radius: 100px;
    padding: 20px 32px;
    color: var(--white-to-black);
    font-size: 21px;
    margin: 30px;
    grid-template-columns: 16px 1fr;
}

.aspin-design .languages.fs .item.active,
.aspin-design .currency.fs .item.active {
    border: 5px solid var(--border-blue);
}

.aspin-design .light-aspin {
    background: var(--aspin-background-input-light) !important;
    border: 1px solid var(--aspin-background-input) !important;
    color: var(--whitesmoke-to-lightgrey) !important;
}

.aspin-design button {
    background: linear-gradient(265.56deg, #246CF9 -0.27%, #1E68F6 -0.26%, #0047D0 98.59%);
    width: 100%;
    border: none;
    height: 45px;
    border-radius: 100px;
    font-size: 16px;
    transition: filter .3s ease;
}

.alert-message-marquee {
    padding: 7px 5px;
    background-color: #beec00;
    color: #000;
    display: flex;
    align-items: center;
    font-size: 14px;
}

@media(hover:hover){
    .aspin-design button:hover {
        filter: brightness(1.5);
    }
}

.aspin-green {
    color: #11CABE !important;
}

.aspin-red {
    color: #FA2256 !important;
}

.switch {
    background-color:#d9d9d9;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    user-select: none;
    width: 60px;

}

aside .switch{
    margin: 20px auto 0;
}

.switch:after{
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
    transform: translateY(-50%);
    background-color: #4b5d6a;
    transition: all .3s ease;
}
.switch.active:after{
    left: 30px;
}
.switch.active div:first-child svg path{
    transition: all .3s ease;
    fill: #258BD4
}
.switch.active div:nth-child(2) svg path{
    transition: all .3s ease;
    fill: #fff
}
.switch div{
    z-index: 8888;
    position: relative;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
}
.switch input{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    cursor: pointer;
}

@media (min-width: 1000px) {
    .aspin-icon {
        right: 2vw;
        width: 1.5vw;
        height: 1.5vw;
        top: calc(50% + .7vw);
    }

    /*.aspin-icon.small {*/
    /*    right: 2vw;*/
    /*    width: 2vw;*/
    /*    height: 2vw;*/
    /*    top: calc(50% + .7vw);*/
    /*}*/

    .aspin-design .languages.fs .item,
    .aspin-design .currency.fs .item {
        border: .1vw solid var(--aspin-background-input);
        border-radius: 10vw;
        padding: .5vw 2vw;
        font-size: 1.2vw;
        margin: .5vw;
        grid-template-columns: 1vw 1fr;
    }

    .aspin-design .languages.fs .item.active,
    .aspin-design .currency.fs .item.active {
        border: .3vw solid var(--border-blue);
    }

    .aspin-design input.fs,
    .aspin-design .form-control.fs {
        border: .1vw solid var(--aspin-background-input);
        border-radius: 10vw;
        height: 3.5vw;
        padding: .8vw 2.4vw;
        color: var(--white-to-black);
    }

    .aspin-design .col.avatar {
        border: .2vw dashed #F3F3F3;
        border-radius: 1vw;
    }

    .aspin-design .quote-block {
        border: .1vw solid #CDCDCD;
        border-radius: 1vw;
        padding: 3vw;
    }

    .card-bg {
        border-radius: .5vw;
        padding: 1vw;
    }

    .quote-block {
        padding: 1vw;
        font-size: 1vw;
    }

    .cabinet th.fs {
        padding: .7vw 0;
        font-size: 1vw;
    }

    tr.fs {
        font-size: .8vw;
        /*border-top: .4vw solid black;*/
    }

    th.fs {
        /*border-right: .6vw solid black;*/
    }

    .cabinet td {
        font-size: 1vw;
    }

    .cabinet td, th {
        text-align: center;
        border-right: 1px solid transparent;
        padding: .4vw 1vw;
    }

    select.fs {
        background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
        background-position: calc(100% - 1.7vw) 1vw, calc(100% - 1.35vw) 1vw, calc(100% - 3vw) 0.4vw;
        background-size: .4vw .4vw, .4vw .4vw, .1vw .5vw;
        background-repeat: no-repeat;
        border: 0.1vw solid rgba(255, 255, 255, 0.3);
    }

    select.fs.activeSelect {
        background-image: linear-gradient(45deg, gray 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, gray 50%);
        background-position: calc(100% - 1.35vw) 1vw, calc(100% - 1.7vw) 1vw, calc(100% - 3vw) 0.4vw;
        background-size: .4vw .4vw, .4vw .4vw, .1vw .5vw;
        background-repeat: no-repeat;
        outline: 0;
    }


    .form-control.fs {
        color: var(--white-to-black);
        padding: .4vw .625vw;
        font-size: 1.1vw;
        height: 2.55vw;
        border-radius: .5vw;
        margin-left: 0px;
    }

    .form-row.fs {
        margin-bottom: 1vw;
    }

    .form-title {
        font-size: 1vw;
        margin-bottom: .5vw;
        margin-top: 1vw;
    }

    .aspin-design .form-title {
        font-size: 1.25vw;
    }

    .form-row:first-child .form-title {
        margin-top: 0;
    }

    .aspin-design .fullscreen-page-title {
        padding: 0 0 4vw 0;
    }

    .fullscreen-page-title {
        font-size: 1.5vw;
        padding: 0 0 2vw 0;
    }

    .fullscreen-page-sub-title {
        font-size: 1vw;
    }

    input.fs {
        font-size: 1vw;
        padding: .5vw .8vw;
        border-radius: .5vw;
        border: .1vw solid var(--background-footer);
    }

    .aspin-design label.fs {
        padding-left: 2.5vw;
        font-size: .8vw;
        color: var(--aspin-color-label);
        display: inline-block;
    }

    label.fs {
        font-size: 1vw;
        margin-bottom: .5vw;
    }

    button.fs {
        font-size: 1.2vw;
        width: 100%;
        padding: .4vw 1vw;
        border-radius: .4vw;
    }

    button.fs,
    button.fs-small,
    button.fs-normal {
        border: .1vw solid rgba(255, 255, 255, 0.3);
        padding: 0 .5vw;
    }

    .aspin-design button.fs,
    .aspin-design button.fs-small,
    .aspin-design button.fs-normal {
        border: none;
    }

    button.fs-small {
        font-size: 1vw;
        padding: .1vw 1vw;
        border-radius: .3vw;
        margin: 0 auto;
    }

    button.fs-normal {
        font-size: 1vw;
        height: 2.55vw;
        border-radius: .3vw;
    }

    .aspin-design button.fs-normal {
        height: 3vw;
    }


    .aspin-design .cabinet th,
    .aspin-design .cabinet td {
        padding: 1vw;
    }

    .aspin-design .col.fs {
        margin-bottom: 1vw;
    }
}

.aspin-design table {
    border-collapse: separate;
    border-spacing: 0 12px;
}

.aspin-design .fs.Approved,
.aspin-design .fs.Declined,
.aspin-design .fs.Pending {
    /* padding: 5px;
    border-radius: 100px;
    text-align: center; */
}

.aspin-design .cancel-withdrawal {
    font-size: 20px;
}

.aspin-design .fs.Pending {
    color: #F3BA2F;
}

.aspin-design .fs.Approved {
    color:  #30E0A1;
}

.aspin-design .fs.Declined {
    color: #e0303f;
}

.aspin-design .table-cont {
    /* background: var(--aspin-background-table); */
    /* border: 2px solid #7C818E; */
    /* border-radius: 12px; */
    padding: 10px 0 0;
}

.aspin-design table.cabinet.fs {
    background: var(--aspin-background-table);
    border: 2px solid #7C818E;
    border-radius: 12px;
    padding: 16px;
}

.light-theme .aspin-design table.cabinet.fs {
    background: inherit
}

.aspin-design .cabinet tbody tr {
    background-color: var(--aspin-background-input)
}


.aspin-design .cabinet th {
    background-color: transparent;
}

.aspin-design .cabinet th,
.aspin-design .cabinet td {
    text-align: start;
    padding: 10px;
    color: var(--white-to-black);
}

/*--------------*/
@media (max-width: 1000px) {
    th.fs {
        border-right: none;
    }

    .col.fs {
        grid-template-columns: 1fr;
        margin-bottom: 20px;
    }

    .row {
        margin-bottom: 10px;
    }

    select.fs {
        background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
        background-position: calc(100% - 15px) calc(1em), calc(100% - 10px) calc(1em), calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        background-repeat: no-repeat;
    }

    select.fs.activeSelect {
        background-image: linear-gradient(45deg, gray 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, gray 50%);
        background-position: calc(100% - 10px) 1em, calc(100% - 15px) 1em, calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px,
        5px 5px,
        1px 1.5em;
        background-repeat: no-repeat;
        border-color: gray;
        outline: 0;
    }
}

@media (max-width: 1023px) {
    html, body {
        overflow: hidden;
    }

    .nomobile {
        display: none !important;
    }

    .blur-container {
        overflow: hidden;
    }
}


@media (max-width: 1000px) {

    .form-row {
        margin-bottom: 15px;
    }

    input.fs, select.fs {
        border-radius: 10px;
    }

    .fullscreen-page-title {
        padding-bottom: 40px;
    }
}

@media (max-width: 999px) {
    .aspin-design .form-title{
        font-size: 18px;
    }

    .aspin-design input.fs,
    .aspin-design .form-control.fs {
        height: 45px;
        font-size: 12px;
    }

    .aspin-icon {
        width: 20px;
        height: 20px;
        top: calc(50% + 10px);
    }

    .aspin-design .languages.fs .item,
    .aspin-design .currency.fs .item {
        margin: 10px 0;
    }
}

@media (max-width: 500px){
.aspin-design input.form-control.fs {
    padding: 1.1em 44px 1.1em 14px;
}
.aspin-design table.cabinet.fs {
    font-size: 10px;
}
}

/*--------------*/

.main {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    background-size: contain;
    position: relative;
}

.bigPane {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-wrapper.aspin-modal-design {
    background-image: url("~@/assets/login-bg.png");
}
</style>

