import { render, staticRenderFns } from "./AssetsInfoModal.vue?vue&type=template&id=44ce585e&scoped=true&"
import script from "./AssetsInfoModal.vue?vue&type=script&lang=js&"
export * from "./AssetsInfoModal.vue?vue&type=script&lang=js&"
import style0 from "./AssetsInfoModal.vue?vue&type=style&index=0&id=44ce585e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ce585e",
  null
  
)

export default component.exports